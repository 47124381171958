import { Button, Checkbox, FormControl, FormControlLabel, FormHelperText, InputLabel, LinearProgress, Link, MenuItem, Paper, Radio, RadioGroup, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import InputValue from '../classes/InputValue';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import reCAPTCHA from '../services/CaptchaService';
import { validationService } from '../validation/ValidationService';

interface AntragProps {
    isMobile: boolean
    config: Config
}
interface StepProps {
    values: any,
    config: Config,
    validate: Function
}


export const Antrag: React.FC<AntragProps> = (props) => {
    //Start recaptcha script in browser
    useEffect(() => {
        const script = document.createElement('script')
        script.src = `https://www.recaptcha.net/recaptcha/api.js?render=${process.env.6LePngwqAAAAADAED5ABwcdS2sTesTx9lFFXnJ-C}`
        document.body.appendChild(script)

        //Request to fetch list of payment options
        axios.get(`${process.env.https://crm.buergerenergie.bayern}/rest/entities/beg_PaymentOption`)
            .then(response => {
                setPaymentOptions(response.data);

            }).catch(error => {
                console.error('Error:', error);
            });
    }, []);

    //number in which step user is
    const [progress, setProgress] = useState<number>(1)
    //boolean if site is currently doing validations => shows "Weiter"-Button if it should show a loading circle
    const [continueBtnIsLoading, setContinueBtnIsLoading] = useState<boolean>(false)
    //shows in what state the application is and shows that by saving the color for upper bar (primary or error or success)
    const [progressColor, setProgressColor] = useState<any>("primary")
    //Saves list of payment options from request
    const [paymentOptions, setPaymentOptions] = useState<Array<any>>([{ code: "seperate", activated: false }, { code: "sepa", activated: false }]);
    //Form data
    const [values] = useState<any>({
        salutation: new InputValue("", false),
        title: new InputValue("", false),
        firstname: new InputValue("", false),
        lastname: new InputValue("", false),
        addressStreet: new InputValue("", false),
        addressExtra: new InputValue("", false),
        addressPostalcode: new InputValue("", false),
        addressCity: new InputValue("", false),
        phone: new InputValue("", false),
        email: new InputValue("", false),
        birthdate: new InputValue("", false),
        birthcity: new InputValue("", false),
        taxnumber: new InputValue("", false),
        //
        shares: new InputValue(1, false),
        //
        bankdataType: new InputValue("", false),
        bankdataIban: new InputValue("", false),
        bankdataOwner: new InputValue("", false),
        bankdataBic: new InputValue("", false),
        bankdataBankname: new InputValue("", false),
        //
        statuteConfirmed: new InputValue(false, false),
        dataProtectionRegulationConfirmed: new InputValue(false, false),
        note: new InputValue("", false)
    });
    //a useState var needs to change in order to reload the screen, so you can do that with reload(!reloadVar);
    const [reloadVar, reload] = useState<boolean>(false)

    //checks validation of value and returns if value is ok (adapts to state with isNextStep)
    const validate = (name: string, checkFor: string, isNextStep: boolean, isRequired: boolean) => {
        const value = values[name].getValue();
        if (isNextStep && isRequired) {
            if (typeof parseInt(value) === 'number' && checkFor === "number") {
                if (value < 1 || value > props.config.maxAllowedHeldShares) {
                    values[name].setError(true);
                    if (isNextStep) { setProgressColor("error") }
                    return false;
                }
            } else {
                if (value.length < 1) {
                    values[name].setError(true);
                    if (isNextStep) { setProgressColor("error") }
                    return false;
                }
            }
            //only iban field (sepa) is required and should not only be checked onBlur but also onNextStep
            if (checkFor === "iban") {
                if (values[name].getError()) return false;
            }
        } else {
            if (typeof parseInt(value) === 'number' && checkFor === "number") {
                if (value.length < 1) {
                    values[name].setError(false);
                    return true;
                }
                if (value < 1 || value > props.config.maxAllowedHeldShares) {
                    values[name].setError(true);
                    if (isNextStep) { setProgressColor("error") }
                    return false;
                }
            } else {
                if (value.length < 1) {
                    values[name].setError(false);
                    return true;
                }
            }
        }
        if (!validationService[checkFor](value)) {
            values[name].setError(true);
            if (isNextStep) { setProgressColor("error") }
            return false;
        }
        values[name].setError(false);
        return true;
    }

    //Both following function give back weather an "error" occured
    const validateIbanFromBackend = async (value: String, resolve: Function) => {
        validate("bankdataIban", "iban", false, false);
        if (!values.bankdataIban.getError()) {
            const recaptcha = new reCAPTCHA((process.env.6LePngwqAAAAADAED5ABwcdS2sTesTx9lFFXnJ-C!), "validateIban");
            //sends request to google
            let token: string = await recaptcha.getToken();

            axios.post(`${process.env.https://crm.buergerenergie.bayern}/rest/services/beg_MemberApplicationService/validateIban`, {
                iban: values.bankdataIban.getValue(),
                grtoken: token
            }).then(function (response) {
                console.log(response);
                if (response.data.status === "OK") {
                    values.bankdataIban.setError(false);
                    if (response.data.body !== null) {
                        const bank = response.data.body;
                        values.bankdataBic.setValue(bank.bic);
                        values.bankdataBankname.setValue(bank.name);
                        values.bankdataBic.setError(false);
                        values.bankdataBankname.setError(false);
                    }
                } else {
                    values.bankdataIban.setError(true);
                    values.bankdataBic.setValue("");
                    values.bankdataBankname.setValue("");
                }
                reload(!reloadVar);
                resolve();
            }).catch(function (error) { console.log(error); })
        } else { resolve(); }
    }
    const checkEmailFromBackend = async (email: String, resolve: Function) => {
        validate("email", "email", false, false);
        if (!values.email.getError()) {
            const recaptcha = new reCAPTCHA((process.env.6LePngwqAAAAADAED5ABwcdS2sTesTx9lFFXnJ-C!), "checkEmail");
            //sends request to google
            let token: string = await recaptcha.getToken();

            axios.post(`${process.env.https://crm.buergerenergie.bayern}/rest/services/beg_MemberApplicationService/checkEmail`, {
                email: values.email.getValue(),
                grtoken: token
            }).then(function (response) {
                console.log(response);
                if (response.data.body === "AVAILABLE") {
                    values.email.setError(false);
                    values.email.setErrorType("");
                } else {
                    values.email.setError(true);
                    values.email.setErrorType("DUPLICATE");
                }
                reload(!reloadVar);
                resolve();
            }).catch(function (error) { console.log(error); })
        } else { resolve(); }
    }

    //request function
    const sendRequest = async () => {
        console.log(process.env.6LePngwqAAAAADAED5ABwcdS2sTesTx9lFFXnJ-C)
        //loads recaptcha (should already be loaded) and creates context
        const recaptcha = new reCAPTCHA((process.env.6LePngwqAAAAADAED5ABwcdS2sTesTx9lFFXnJ-C!), "antrag");
        //sends request to google
        let token: string = await recaptcha.getToken();

        axios.post(`${process.env.https://crm.buergerenergie.bayern}/rest/services/beg_MemberApplicationService/createApplication`, {
            application: {
                salutation: values.salutation.getValue(),
                title: values.title.getValue(),
                firstname: values.firstname.getValue(),
                lastname: values.lastname.getValue(),
                address_street: values.addressStreet.getValue(),
                address_extra: values.addressExtra.getValue(),
                address_postalcode: values.addressPostalcode.getValue(),
                address_city: values.addressCity.getValue(),
                phone: values.phone.getValue(),
                email: values.email.getValue(),
                birthdate: values.birthdate.getValue(),
                birthcity: values.birthcity.getValue(),
                taxnumber: values.taxnumber.getValue(),
                shares: values.shares.getValue(),
                bankdata_type: values.bankdataType.getValue(),
                bankdata_iban: values.bankdataIban.getValue(),
                bankdata_accountowner: values.bankdataOwner.getValue(),
                bankdata_bic: values.bankdataBic.getValue(),
                bankdata_bankname: values.bankdataBankname.getValue(),
                note: values.note.getValue()
            },
            grtoken: token
        }).then(function (response) {
            console.log(response);
            if (response.data.status === "CREATED") {
                setProgressColor("success");
                setProgress(6);
            } else {
                setProgressColor("error");
                setProgress(7);
            }
        }).catch(function (error) {
            if (error.response.status === 500) {
                setProgressColor("error");
                setProgress(7);
            }
        })
    }

    return (
        <div style={{
            padding: props.isMobile ? 10 : 20,
            //boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
            margin: props.isMobile ? "10px 8px" : "20px 25%",
            marginBottom: props.isMobile ? 80 : 20,
            width: props.isMobile ? "calc(100% - 16px - 20px)" : "calc(50% - 40px)"
        }}
        >
            <Typography variant="h4" sx={{ marginBottom: "5px", display: "inline-block" }}>Mitgliedsantrag <Typography variant="h5" sx={{ display: "inline-block" }}>({progress < 5 ? progress : 4}/4)</Typography></Typography>
            <Box sx={{ width: '100%', marginBottom: "15px" }}>
                <LinearProgress variant="determinate" color={progressColor} value={progress * 25 < 100 ? progress * 25 : 100} />
            </Box>
            {/*progress=5 is only used so Step 4 can see when it should load*/}
            {progress === 1 ? <Step1 values={values} config={props.config} validate={validate} checkEmailFromBackend={checkEmailFromBackend} /> :
                progress === 2 ? <Step2 values={values} config={props.config} validate={validate} /> :
                    progress === 3 ? <Step3 values={values} config={props.config} validate={validate} paymentOptions={paymentOptions} validateIbanFromBackend={validateIbanFromBackend} /> :
                        progress === 4 ? <Step45 values={values} config={props.config} validate={validate} progress={progress} /> :
                            progress === 5 ? <Step45 values={values} config={props.config} validate={validate} progress={progress} /> :
                                progress === 6 ? step6 :
                                    progress === 7 ? step7 :
                                        <></>}

            <div style={{ textAlign: "right" }}>
                {/*Back-Buttons are handled here, depending on the progress*/
                    progress > 1 && progress <= 4 ?
                        <Button variant='outlined' disableElevation sx={{ marginTop: "10px", marginRight: "10px" }} onClick={() => { setProgress(progress - 1); setProgressColor("primary") }}>Zurück</Button> :
                        progress <= 2 ?
                            <Button disabled disableElevation sx={{ marginTop: "10px", marginRight: "10px" }}>Zurück</Button> :
                            progress === 7 ? <Button variant='outlined' disableElevation sx={{ marginTop: "10px", marginRight: "10px" }} onClick={() => { setProgress(4); setProgressColor("primary") }}>Zurück</Button> : <></>
                }
                {/*Validation and error handling was refactored*/}
                {/*Next code handels what happens after you click "Weiter", progress is the number of the current step*/}
                {progress < 4 ?
                    <LoadingButton loading={continueBtnIsLoading} variant="contained" disableElevation sx={{ marginTop: "10px" }} onClick={async () => {
                        setContinueBtnIsLoading(true);
                        if (progress === 1) {
                            let isError = false;
                            //name,checkFor,isNextStep,isRequired
                            if (!validate("salutation", "alphabet", true, true)) isError = true;
                            if (!validate("title", "alphabet", true, false)) isError = true;
                            if (!validate("firstname", "alphabet", true, true)) isError = true;
                            if (!validate("lastname", "alphabet", true, true)) isError = true;
                            if (!validate("addressStreet", "street", true, true)) isError = true;
                            if (!validate("addressExtra", "nocheck", true, false)) isError = true;
                            if (!validate("addressPostalcode", "postalcode", true, true)) isError = true;
                            if (!validate("addressCity", "alphabet", true, true)) isError = true;
                            if (!validate("phone", "phone", true, false)) isError = true;
                            if (!validate("email", "email", true, true)) isError = true;
                            await new Promise(resolve => checkEmailFromBackend(values.email.getValue(), resolve));
                            if (values.email.getError()) isError = true;
                            if (!validate("birthdate", "birthdate", true, true)) isError = true;

                            if (!validate("birthcity", "alphabet", true, false)) isError = true;
                            if (!validate("taxnumber", "taxnumber", true, false)) isError = true;
                            reload(!reloadVar);
                            if (!isError) {
                                setProgress(progress + 1);
                                setProgressColor("primary")
                            }

                        } else if (progress === 2) {
                            let isError = false;
                            //name,checkFor,isNextStep,isRequired
                            if (!validate("shares", "number", true, true)) isError = true;
                            reload(!reloadVar);
                            if (!isError) {
                                setProgress(progress + 1);
                                setProgressColor("primary")
                            }

                        } else if (progress === 3) {

                            if (values.bankdataType.getValue().length > 0) {
                                if (values.bankdataType.getValue() === "sepa") {
                                    let isError = false;
                                    //name,checkFor,isNextStep,isRequired
                                    if (!validate("bankdataIban", "iban", true, true)) isError = true;
                                    await new Promise(resolve => validateIbanFromBackend(values.bankdataIban.getValue(), resolve));
                                    if (values.bankdataIban.getError()) isError = true;
                                    if (!validate("bankdataOwner", "alphabet", true, true)) isError = true;
                                    if (!validate("bankdataBic", "bic", true, true)) isError = true;
                                    if (!validate("bankdataBankname", "alphabet", true, true)) isError = true;
                                    reload(!reloadVar);
                                    if (!isError) {
                                        setProgress(progress + 1);
                                        setProgressColor("primary")
                                    }
                                } else {
                                    values["bankdataType"] = new InputValue("seperate", false);
                                    values["bankdataIban"] = new InputValue("", false);
                                    values["bankdataOwner"] = new InputValue("", false);
                                    values["bankdataBic"] = new InputValue("", false);
                                    values["bankdataBankname"] = new InputValue("", false);
                                    setProgress(progress + 1);
                                    setProgressColor("primary")
                                }

                            } else {
                                setProgressColor("error")
                            }

                        } else {
                            setProgress(progress + 1)
                        }
                        setContinueBtnIsLoading(false);
                    }}>Weiter</LoadingButton> :
                    progress === 4 || progress === 5 ?

                        <LoadingButton loading={progress === 5 ? true : false} variant="contained" disableElevation sx={{ marginTop: "10px" }} onClick={() => {
                            let isError = false;
                            if (values.statuteConfirmed.getValue() !== true) {
                                isError = true;
                                values.statuteConfirmed.setError(true);
                            }
                            if (values.dataProtectionRegulationConfirmed.getValue() !== true) {
                                isError = true;
                                values.dataProtectionRegulationConfirmed.setError(true);
                            }
                            if (isError) {
                                setProgressColor("error");
                                reload(!reloadVar);
                                return;
                            }
                            setProgress(5);
                            sendRequest();

                        }}>Mitgliedsantrag abschicken</LoadingButton> : <></>
                }
            </div>
        </div>
    )
}



//STEP 111

interface Step1Props {
    values: any,
    config: Config,
    validate: Function,
    checkEmailFromBackend: Function
}


const Step1: React.FC<Step1Props> = (props) => {
    const style = {
        marginTop: "10px",
    }
    const [reloadVar, reload] = useState<boolean>(false);
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, checkFor: string) => {
        const target = e.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        if (name === "taxnumber") {
            value = (value as string).replaceAll(" ", "");
        }
        props.values[name].setValue(value);
        //in this step required can be false (even if its true) because required is checked on submit, not on change
        props.validate(name, checkFor, false, false);
        reload(!reloadVar);
    }
    //können zu error gesetzt werden wenn übergeben wird error={error}
    return (<>
        <Paper elevation={0}>
            <Typography variant='subtitle1'>Persönliche Angaben</Typography>

            <FormControl style={style} fullWidth>
                <InputLabel size='small'>Anrede *</InputLabel>
                <Select
                    value={props.values.salutation.getValue()}
                    label="Anrede"
                    name='salutation'
                    variant="outlined"
                    size="small"
                    //Not compatible with handlechange() as it's a different event
                    onChange={(e: SelectChangeEvent<HTMLInputElement>) => {
                        props.values[e.target.name].setValue(e.target.value);
                        reload(!reloadVar);
                    }}
                    error={props.values.salutation.getError()}
                    required
                >
                    <MenuItem value={"Herr"}>Herr</MenuItem>
                    <MenuItem value={"Frau"}>Frau</MenuItem>
                    <MenuItem value={"Keine Anrede"}>Keine Anrede</MenuItem>
                </Select>
            </FormControl>
            <TextField style={style} fullWidth value={props.values.title.getValue()} error={props.values.title.getError()} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, "alphabet")} name='title' label="Titel" variant="outlined" size="small" />
            <TextField style={style} fullWidth value={props.values.firstname.getValue()} error={props.values.firstname.getError()} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, "alphabet")} name='firstname' label="Vorname" variant="outlined" size="small" required />
            <TextField style={style} fullWidth value={props.values.lastname.getValue()} error={props.values.lastname.getError()} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, "alphabet")} name='lastname' label="Nachname" variant="outlined" size="small" required />
            <TextField style={style} fullWidth value={props.values.addressStreet.getValue()} error={props.values.addressStreet.getError()} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, "street")} name='addressStreet' label="Straße, Hausnummer" variant="outlined" size="small" required />
            <TextField style={style} fullWidth value={props.values.addressExtra.getValue()} error={props.values.addressExtra.getError()} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, "nocheck")} name='addressExtra' label="Addresszusatz (optional)" variant="outlined" size="small" />
            <TextField style={style} fullWidth value={props.values.addressPostalcode.getValue()} error={props.values.addressPostalcode.getError()} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, "postalcode")} name='addressPostalcode' label="Postleitzahl" variant="outlined" size="small" required />
            <TextField style={style} fullWidth value={props.values.addressCity.getValue()} error={props.values.addressCity.getError()} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, "alphabet")} name='addressCity' label="Ort" variant="outlined" size="small" required />
            <TextField style={style} fullWidth value={props.values.phone.getValue()} error={props.values.phone.getError()} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, "phone")} name='phone' type='tel' label="Telefon (optional)" variant="outlined" size="small" />
            <TextField style={style} fullWidth value={props.values.email.getValue()} error={props.values.email.getError()} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, "email")} onBlur={() => props.checkEmailFromBackend(props.values.email.getValue, () => { })} name='email' type='email' label="E-Mail" variant="outlined" size="small" required />
            <FormHelperText>{props.values.email.getErrorType() === "DUPLICATE" ? 'Diese E-Mail-Adresse wird bereits verwendet' : ''}</FormHelperText>

            <Typography variant='body1' style={{ ...style, width: "125px", display: "inline-block", padding: "8px 0px" }}>Geburtsdatum*</Typography>
            <TextField style={{ ...style, width: "calc(100% - 125px)", display: "inline-block" }} fullWidth value={props.values.birthdate.getValue()} error={props.values.birthdate.getError()} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, "birthdate")} name='birthdate' type='date' variant="outlined" size="small" required />
            <TextField style={style} fullWidth value={props.values.birthcity.getValue()} error={props.values.birthcity.getError()} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, "alphabet")} name='birthcity' label="Geburtsort (optional)" variant="outlined" size="small" />
            <TextField style={style} fullWidth value={props.values.taxnumber.getValue()} error={props.values.taxnumber.getError()} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, "taxnumber")} name='taxnumber' label="Steuer-ID (optional)" variant="outlined" size="small" helperText="optional in diesem Schritt, erst notwendig ab Zinsauszahlung" />
        </Paper>
    </>)
}


//STEP 222

const Step2: React.FC<StepProps> = (props) => {
    const style = {
        marginTop: "10px",
    }
    const [reloadVar, reload] = useState<boolean>(false);
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const valueBefore = props.values["shares"].getValue();
        props.values["shares"].setValue(e.target.value)
        if (!props.validate("shares", "number", false, false) || (parseFloat(e.target.value) % 1 !== 0 && e.target.value !== "")) {
            props.values["shares"].setValue(valueBefore);
        }

        reload(!reloadVar);
    }
    //
    return (<>
        <Paper elevation={0}>
            <Typography variant='subtitle1'>Beitrittserklärung</Typography>

            <Typography variant="body2">
                <b>Ich erkläre hiermit den Beitritt zu der {props.config.cooperativeName} und zeichne folgende Anzahl an Anteilen:</b>
            </Typography>

            <TextField type='number' style={style} value={props.values.shares.getValue()} onChange={handleChange} error={props.values.shares.getError()} name='shares' label="Anzahl Anteile" variant="outlined" size="small" />
            <Typography variant="body1" style={style}>
                Zu zahlende Gesamtsumme: <b>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(props.values.shares.getValue() * (props.config.nominalShareValue + props.config.sharePurchaseFeeRate))}</b>
            </Typography>
            <Typography variant="caption" style={style} >
                Hinweis : Ein Geschäftsanteil beträgt {props.config.nominalShareValue} €, zusätzlich ist ein Aufgeld von {props.config.sharePurchaseFeeRate} Euro pro Anteil zu bezahlen. Jedes Mitglied darf
                maximal {props.config.maxAllowedHeldShares} Anteile besitzen. Ich verpflichte mich, die nach Gesetz und Satzung geschuldeten Einzahlungen auf den/die
                Geschäftsanteil(e) zu leisten. Nach Eingang meiner Beitrittserklärung bei der {props.config.cooperativeName} erhalte ich umgehend eine schriftliche Bestätigung.
            </Typography>
        </Paper>
    </>)
}


//STEP 333

interface Step3Props {
    values: any,
    config: Config,
    validate: Function,
    paymentOptions: Array<any>,
    validateIbanFromBackend: Function
}


const Step3: React.FC<Step3Props> = (props) => {
    const style = {
        marginTop: "10px",
    }
    const [reloadVar, reload] = useState<boolean>(false);
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, checkFor: string) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        props.values[name].setValue(value);
        //in this step required can be false (even if its true) because required is checked on submit, not on change
        props.validate(name, checkFor, false, false);
        reload(!reloadVar);
    }

    return (<>
        <Paper elevation={0}>
            <Typography variant='subtitle1'>Zahlungsweise</Typography>

            <Typography variant="body2">
                Um Verwaltungsaufwand zu sparen, bitten wir Sie, am Einzugsermächtigungsverfahren teilzunehmen. Vielen Dank!
            </Typography>

            <RadioGroup
                defaultValue=""
                name="bankdataType"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, "alphabet")}
                value={props.values.bankdataType.getValue()}
            >
                {props.paymentOptions.find((e: any) => e.code === "sepa").activated === true ?
                    <FormControlLabel value="sepa" control={<Radio />} label="SEPA-Lastschrift" /> :
                    <FormControlLabel value="sepa" control={<Radio />} label="SEPA-Lastschrift (Coming soon)" disabled />
                }

                {props.values.bankdataType.getValue() === 'sepa' && (<>
                    <TextField style={style} fullWidth value={props.values.bankdataIban.getValue()} error={props.values.bankdataIban.getError()} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, "iban")} onBlur={() => props.validateIbanFromBackend(props.values.bankdataIban.getValue, () => { })} name='bankdataIban' label="IBAN" variant="outlined" size="small" required />
                    <TextField style={style} fullWidth value={props.values.bankdataOwner.getValue()} error={props.values.bankdataOwner.getError()} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, "alphabet")} name='bankdataOwner' label="Kontoinhaber" variant="outlined" size="small" required />
                    <TextField style={style} fullWidth value={props.values.bankdataBic.getValue()} error={props.values.bankdataBic.getError()} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, "bic")} name='bankdataBic' label="BIC" variant="outlined" size="small" required />
                    <TextField style={style} fullWidth value={props.values.bankdataBankname.getValue()} error={props.values.bankdataBankname.getError()} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, "alphabet")} name='bankdataBankname' label="Kreditinstitut" variant="outlined" size="small" required />
                    <Typography variant="body2" style={style} >
                        Mit einem Klick auf "Weiter" erteile ich der {props.config.bank_owner} ein SEPA-Basismandat und gestatte widerruflich den fälligen Betrag von nachstehend
                        genanntem Konto einzuziehen. Die Gläubiger-Identifikationsnummer ist {props.config.creditorIdentificationNumber}.
                    </Typography>
                </>)}

                {props.paymentOptions.find((e: any) => e.code === "seperate").activated ?
                    <FormControlLabel value="seperate" control={<Radio />} label="Eigene Überweisung" /> :
                    <FormControlLabel value="seperate" control={<Radio />} label="Eigene Überweisung (Nicht möglich)" disabled />
                }
                {props.values.bankdataType.getValue() === 'seperate' && (<>
                    <Typography variant="body2" >
                        Ich überweise den fälligen Betrag nach Erhalt der Mitgliedsbestätigung selbst
                    </Typography>
                </>)}
            </RadioGroup>

        </Paper>
    </>)
}


//STEP 444555

interface Step45Props {
    values: any,
    config: Config,
    validate: Function,
    progress: number
}


const Step45: React.FC<Step45Props> = (props) => {
    const style = {
        marginTop: "10px",
    }
    const [reloadVar, reload] = useState<boolean>(false);
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        props.values[name].setValue(value);
        props.values[name].setError(false);
        reload(!reloadVar);
    }

    return <>
        <Typography variant='subtitle1'>Widerrufsbelehrung</Typography>
        <Typography variant="body2">
            Sie können Ihre Beitrittserklärung ohne Angabe von Gründen innerhalb von zwei Wochen in Textform (z. B. Brief, Fax, E-Mail )
            widerrufen. Die Frist beginnt spätestens mit Erhalt dieser Belehrung. Zur Wahrung der Widerrufsfrist genügt der rechtzeitige
            Widerruf per Post an {props.config.cooperativeName}, {props.config.address_street}, {props.config.address_extra.length > 0 && props.config.address_extra + ", "} {props.config.address_postalcode} {props.config.address_city} oder per E-Mail
            an <Link href={`mailto:${props.config.email}`}>{props.config.email}</Link>

        </Typography>

        <Typography variant='subtitle1'>Satzung</Typography>
        <FormControlLabel
            label={<Typography variant="body2">Ich bestätige, dass mir die Satzung der {props.config.cooperativeName} vor Unterzeichnung der
                nachfolgenden Beitrittserklärung übergeben worden ist oder ich diese unter <Link onClick={() => window.open(`${props.config.statuteUrl}`, "_blank")}>Satzung der {props.config.cooperativeName}</Link> heruntergeladen habe.
            </Typography>}

            control={
                <Checkbox
                    name='statuteConfirmed'
                    value={props.values.statuteConfirmed.getValue()}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}

                    required
                />
            }
        />
        <FormHelperText>{props.values.statuteConfirmed.getError() ? 'Bitte bestätigen Sie die Satzung' : ''}</FormHelperText>

        <Typography variant='subtitle1'>Datenschutzerklärung</Typography>
        <FormControlLabel
            label={<Typography variant="body2">Ich bestätige, dass ich die <Link onClick={() => window.open(`${props.config.dataProtectionRegulationUrl}`, "_blank")}>Datenschutzerklärung</Link> aufmerksam durchgelesen habe.
            </Typography>}

            control={
                <Checkbox
                    name='dataProtectionRegulationConfirmed'
                    value={props.values.dataProtectionRegulationConfirmed.getValue()}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}

                    required
                />
            }
        />
        <FormHelperText>{props.values.dataProtectionRegulationConfirmed.getError() ? 'Bitte bestätigen Sie die Datenschutzerklärung' : ''}</FormHelperText>

        <Typography variant='subtitle1'>Anhang</Typography>
        <TextField style={style} multiline fullWidth value={props.values.note.getValue()} error={props.values.note.getError()} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)} name='note' label="Bemerkung (optional)" variant="outlined" size="small" />

    </>
}


//STEP 66
//
const step6 = (<>
    <Typography variant='subtitle1'>Bestätigung</Typography>
    <Typography variant="body2">
        Herzlichen Dank, wir freuen uns, dass Sie gemeinsam mit uns
        die Energiewende vor Ort vorantreiben wollen. Ihr Mitgliedsantrag wurde erfolgreich versendet
        und wird nun von der Vorstandschaft geprüft. Sobald die Prüfung erfolgt ist, erhalten Sie weitere
        Unterlagen und die nächsten Schritte per Mail.
    </Typography>
</>)

//STEP 777 Fehler

const step7 = (<>
    <Typography variant='subtitle1'>Fehler</Typography>
    <Typography variant="body2">
        Beim versenden ihres Mitgliedsantrags ist ein Fehler aufgetreten. Der Antrag wurde somit vermutlich nicht übermittelt. Bitte versuchen sie es erneut, falls Sie dann wieder
        diesen Text angezeigt bekommen, benachrichtigen Sie uns bitte.
    </Typography>
</>)