import { Bank_Account, MemberDto } from '../types';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import AuthService from '../services/AuthService';
import DataService from '../services/DataService';
import EmptyTypesService from '../services/EmptyTypesService';
import EnumToStringService from '../services/EnumToStringService';
import { Loading } from '../components/Loading';
import MemberService from '../services/MemberService';
import { TitleTable } from '../components/TitleTable';
import { useNavigate } from 'react-router-dom';

interface PersonalDataProps {
    isMobile: boolean;
    config: Config;
}

export const PersonalData: React.FC<PersonalDataProps> = (props) => {
    const navigate = useNavigate();
    const [finishedLoading, setFinishedLoading] = useState<{ member: boolean, bank: boolean }>({ member: false, bank: false });
    const [memberData, setMemberData] = useState<MemberDto>(EmptyTypesService.emptyMember());
    const [bankData, setBankData] = useState<Bank_Account[]>([]);

    useEffect(() => {
        if (!AuthService.isLoggedIn()) {
            AuthService.doLogin();
        } else {
            DataService.performRequest(`/member/info${MemberService.getUrlAddition()}`, finishedLoading, setFinishedLoading, setMemberData, 'member', navigate);
            DataService.performRequest(`/bankaccount${MemberService.getUrlAddition()}`, finishedLoading, setFinishedLoading, setBankData, 'bank', navigate);
        }
    }, []);

    let bankAccountCounter = 0;

    const buildBankAccountTypeString = (array: string[]) => {
        let string = '';
        array.map(e => (string += '' + EnumToStringService.getBankAccountType(e) + ', '));
        return string.substring(0, string.length - 2);
    };

    if (!finishedLoading.bank || !finishedLoading.member) {
        return <Loading />;
    }

    return (
        <Box
            display='flex'
            justifyContent='center'
            alignItems='flex-start'
            p={3}
        >
            <Box
                width={props.isMobile ? '100%' : '1024px'}
            >
                <Typography variant='h6' gutterBottom>
                    Persönliche Daten
                </Typography>

                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Box mb={2}>
                            <TitleTable
                                title='Kontaktdaten'
                                rows={memberData.type === 'beg_Contacts' ? [
                                    { name: 'Mitgliedsnummer', value: memberData.memberNumber },
                                    { name: 'Anrede', value: memberData.salutation },
                                    { name: 'Titel', value: memberData.title },
                                    { name: 'Vorname', value: memberData.firstname },
                                    { name: 'Nachname', value: memberData.lastname },
                                    { name: 'Geburtsname', value: memberData.birthname },
                                    { name: 'Geburtsdatum', value: new Date(memberData.birthdate).toLocaleDateString() },
                                    { name: 'Geburtsort', value: memberData.birthplace },
                                    { name: 'Steuer-ID', value: memberData.taxNumber },
                                    { name: 'Konfession', value: 'Anzeige noch nicht verfügbar' },
                                    { name: 'Straße', value: memberData.address_street },
                                    { name: 'Adresszusatz', value: memberData.address_extra },
                                    { name: 'Postleitzahl', value: memberData.address_postalcode },
                                    { name: 'Ort', value: memberData.address_city },
                                    { name: 'Telefon', value: memberData.phone },
                                    { name: 'E-Mail-Adresse', value: memberData.primaryEmail },
                                ] : [
                                    { name: 'Mitgliedsnummer', value: memberData.memberNumber },
                                    { name: 'Name', value: memberData.name },
                                    { name: 'Straße', value: memberData.address_street },
                                    { name: 'Adresszusatz', value: memberData.address_extra },
                                    { name: 'Postleitzahl', value: memberData.address_postalcode },
                                    { name: 'Ort', value: memberData.address_city },
                                    { name: 'Telefon', value: memberData.phone },
                                    { name: 'E-Mail-Adresse', value: memberData.primaryEmail },
                                    { name: 'Umsatzsteuer-ID', value: memberData.taxIdNumber },
                                ]}
                            />
                        </Box>
                        <Button fullWidth variant='outlined' onClick={() => navigate('/personaldata/edit')}>Bearbeiten</Button>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Box>
                            {bankData.map((ba, index) => (
                                <Box key={ba.id} mb={2}>
                                    <TitleTable
                                        title={`${index + 1}. Bankverbindung`}
                                        rows={[
                                            { name: 'Kontoinhaber', value: ba.name },
                                            { name: 'IBAN', value: ba.iban },
                                            { name: 'BIC', value: ba.bank.bic },
                                            { name: 'Name der Bank', value: ba.bank.name },
                                            { name: 'Einzugsermächtigung', value: ba.debit ? 'Ja' : 'Nein' },
                                            { name: 'In Verwendung für', value: buildBankAccountTypeString(ba.bankAccountTypes) },
                                        ]}
                                    />
                                    <Button
                                        fullWidth
                                        variant='outlined'
                                        onClick={() => navigate(`/bankaccount/edit/${ba.id}`)}
                                        sx={{ mt: 1 }}
                                    >
                                        Bearbeiten
                                    </Button>
                                </Box>
                            ))}
                            <Button
                                fullWidth
                                variant='outlined'
                                sx={{ mt: bankData.length > 0 ? 2 : 0 }}
                                onClick={() => navigate('/bankaccount/create')}
                            >
                                Neues Konto Hinzufügen
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

