import { Bank_Account, Loan, MemberDto, ProjectDto } from "../types";

const emptyMember = () :MemberDto => {
    return {
        id: "",
        address_city: "",
        address_extra: "",
        address_postalcode: "",
        address_street: "",
        isMember: false,
        isMemberSince: new Date(),
        primaryEmail: "",
        name: "",
        memberNumber: "",

        salutation: "",
        firstname: "",
        lastname: "",
        birthdate: "",
        birthplace: "",
        birthname: "",
        taxNumber: "",
        title:"",

        taxIdNumber: "",
        website: "",

        phone: "",
        type: "",
        firstSharesPayedDate: undefined
    }
};
const emptyProject = () :ProjectDto => {
    return {
        id: "",
        name: "Nicht vorhanden",
        website: "#",
        state: "Unbekannt",
        desiredLoanSum: 0,
        maxSignedLoansSum: 0,
        minSignedLoansSum: 0,
        number: "",
        interestRate: "",
        loanTerm: "",
        isPublic: true,
        loanContractFileRef: "",
        loanConditionsDocumentFileRef: "",
        riskInformationDocumentFileRef: ""
    }
};
const emptyBankAccount = () :Bank_Account => {
    return {
        id: "",
        name: "",
        iban: "",
        debit: true,
        bank:{bic:"",name:""},
        bankAccountTypes: []
    }
};
const emptyLoan = () :Loan => {
    return {
        id: "Nicht vorhanden",
        name: "Nicht vorhanden",
        value: 0.00,
        signedDate: "Nicht vorhanden",
        project: {name:"Nicht vorhanden"},
        payedDate: "Nicht vorhanden",
        state: "Unbekannt",
        lastModifiedDate: new Date()
    }
};



const EmptyTypesService = {
    emptyMember,
    emptyProject,
    emptyBankAccount,
    emptyLoan,
};

export default EmptyTypesService;