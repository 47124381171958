import { Bank_Account, Loan, MemberDto, ProjectDto } from '../types';

import AuthService from "./AuthService";
import axios from 'axios';

class BankaccountService {
    static async getForMember(memberId: string): Promise<Bank_Account[]>{
        await AuthService.updateToken(null);
        return axios.get(`${process.env.https://crm.buergerenergie.bayern}/bankaccount`, {params: {memberId}, headers: { Authorization: `Bearer ${AuthService.getToken()}`} })
        .then((response) => {
            if(response.status === 200) return response.data
        });
    }
}

export default BankaccountService;

