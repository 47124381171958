import { Alert, Box, Button, Checkbox, FormControlLabel, Link, Stack, Typography } from '@mui/material';
import { Bank_Account, Loan, MemberDto, ProjectDto, TaskDto } from '../../../types';
import { useEffect, useState } from 'react';
import { validateMember, validateMemberBankAccounts, validateMemberFirstSharesDateIsSet } from '../../../validation/memberValidationService';

import AuthService from '../../../services/AuthService';
import BankaccountService from '../../../services/BankaccountService';
import DataService from '../../../services/DataService';
import EmptyTypesService from '../../../services/EmptyTypesService';
import LoanService from '../../../services/LoanService';
import MemberService from '../../../services/MemberService';
import { displayValidationErrors } from '../../../components/ValidationErrors';
import { useNavigate } from 'react-router-dom';

interface CheckChangesTaskProps {
    isMobile: boolean;
    task: TaskDto;
}

declare global {
}

export const SignLoanContractTask: React.FC<CheckChangesTaskProps> = (props) => {
    // LoadingDtos
    const [project, setProject] = useState<ProjectDto | null>(null);
    const [finishedLoadingProject, setFinishedLoadingProject] = useState<boolean>(false);

    const [member, setMember] = useState<MemberDto>(EmptyTypesService.emptyMember());
    const [finishedLoadingMember, setFinishedLoadingMember] = useState<boolean>(false);

    const [loan, setLoan] = useState<Loan>(EmptyTypesService.emptyLoan());
    const [finishedLoadingLoan, setFinishedLoadingLoan] = useState<boolean>(false);

    const [riskInfoChecked, setRiskInfoChecked] = useState(false);
    const [contractDownloaded, setContractDownloaded] = useState(false);
    const [termsChecked, setTermsChecked] = useState(false);

    const [validationErrors, setValidationErrors] = useState<string[]>(["Validiere Mitgliedsdaten!"])
    const [isPreview, setIsPreview] = useState(true);
    const navigate = useNavigate();

    const [bankAccounts, setBankAccounts] = useState<Bank_Account[]>([]);
    const [finishedLoadingBankAccounts, setFinishedLoadingBankAccounts] = useState<boolean>(false);

    const isOrganisation = () => {
        return member?.type !== 'beg_Contacts';
    };

    useEffect(() => {
        if (!AuthService.isLoggedIn()) {
            AuthService.doLogin();
        }
    }, []);

    useEffect(() => {
        if (finishedLoadingMember && finishedLoadingBankAccounts) {
            let validationErrors = validateMember(member)
            validationErrors = [...validationErrors, ...validateMemberFirstSharesDateIsSet(member)]
            validationErrors = [...validationErrors, ...validateMemberBankAccounts(bankAccounts)]
            setValidationErrors(validationErrors)
        }

    }, [member, bankAccounts])

    useEffect(() => {
        const loanId = props.task.variables.loanId as string;
        if (loanId && AuthService.isLoggedIn()) {
            LoanService.getById(loanId).then((loan: Loan) => {
                setLoan(loan);
                setFinishedLoadingLoan(true);
            }).catch((error) => {
                console.error('Error loading loan:', error);
            });

            LoanService.getProjectForLoan(loanId).then((project: ProjectDto) => {
                setProject(project);
                setFinishedLoadingProject(true);
            }).catch((error) => {
                console.error('Error loading project for loan:', error);
            });

            LoanService.getMemberForLoan(loanId).then((member: MemberDto) => {
                setMember(member);
                setFinishedLoadingMember(true);

                BankaccountService.getForMember(member.id).then((bankAccounts: Bank_Account[]) => {
                    setBankAccounts(bankAccounts);
                    setFinishedLoadingBankAccounts(true);
                }).catch((error) => {
                    console.error('Error loading bankAccounts for member:', member.id);
                });
            }).catch((error) => {
                console.error('Error loading member for loan:', error);
            });


        }
    }, [props.task, MemberService]);

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="row"
        >
            <Box
                width={props.isMobile ? '100%' : '1024px'}
                display="flex"
                flexDirection="column"
                className="content"
            >
                {validationErrors.length > 0 &&
                    (<>
                        <Typography variant="h6">Profil noch nicht vollständig!</Typography>
                        <Typography>Bevor Sie den Darlehensvertrag abschließen können müssen noch folgende Fehler behoben werden oder Daten in <Link onClick={() => navigate('/personaldata/edit')}>Ihrem Profil</Link> hinterlegt werden:</Typography>
                        <br />
                        {displayValidationErrors(validationErrors)}
                    </>)}

                <Box
                    className='content'
                    display='flex'
                    justifyContent='center'
                    alignItems='flex-start'
                    padding={2}
                >
                    <Box
                        className='content'
                        display='flex'
                        flexDirection='column'
                        alignItems='center'
                        justifyContent='center'
                        padding={3}
                        boxShadow={3}
                        borderRadius={2}
                        border={1}
                        borderColor='grey.300'
                        bgcolor='background.paper'
                        maxWidth={600}
                        width='100%'
                    >


                        {isPreview && (<>
                            <Typography align='center' variant='h5' sx={{ mt: 2 }}>Herzlichen Glückwunsch! Ihre Zeichnungsabsicht wurde freigegeben!</Typography>
                            <br />
                            <Typography align='center'>Bitte überprüfen Sie abschließend die Informationen zu Ihrem Darlehensvertrag! Wenn etwas nicht stimmt wenden Sie sich bitte telefonisch oder per Mail an uns.</Typography>
                            <br />
                            <Typography><strong>Projekt:</strong> {project?.name}</Typography>
                            <Typography><strong>Betrag des Darlehens:</strong> {loan.desiredValue?.toLocaleString('de-DE')} €</Typography>
                            <Typography><strong>Laufzeit:</strong> {project?.loanTerm}</Typography>
                            <Typography><strong>Zinssatz:</strong> {project?.interestRate}</Typography>
                            <Typography><strong>Zahlungsoption:</strong> Banküberweisung</Typography>

                            <br />
                            <Typography align='center'>Bitte überprüfen Sie auch abschließend Ihre persönlichen Daten im generierten Darlehensvertrag auf der nächsten Seite!</Typography>
                            <br />
                            <Button
                                disabled={validationErrors.length > 0}
                                variant='contained'
                                onClick={() => setIsPreview(false)}
                            >
                                Weiter
                            </Button>
                        </>)}
                        {!isPreview && (<><Typography variant='h5' gutterBottom>
                            Darlehensvertrag abschließen
                        </Typography>

                            <Typography paragraph>
                                Bitte laden Sie den generierten Darlehensvertrag herunter und kontrollieren Sie die darin enthaltenen Daten auf ihre Richtigkeit.
                                Eine Unterschrift ist für den Vertragsabschluss nicht notwendig.
                                Durch das Setzen der Häkchen und den Klick auf den Button "Darlehensvertrag rechtskräftig abschließen" wird der Darlehensvertrag rechtsverbindlich abgeschlossen.
                                Anschließend werden Sie per Mail dazu aufgefordert die entsprechende Darlehenssumme an unser Konto zu überweisen.
                                Vielen Dank für Ihre Unterstützung!
                            </Typography>

                            <Button
                                variant='contained'
                                onClick={() => {
                                    setContractDownloaded(true);
                                    DataService.downloadAttachment(
                                        props.task.variables['loanContractFileRef'],
                                        props.task.variables['loanContractFileName'],
                                    )
                                }}
                                sx={{ marginBottom: 2 }}
                            >
                                {props.task.variables['loanContractFileName']} Herunterladen
                            </Button>

                            <Typography sx={{ mb: 3 }}>
                                Sie finden den Darlehensvertrag auch unter <Link href='/attachments'>Dokumente</Link>.
                            </Typography>

                            {project?.riskInformationDocumentFileRef && (
                                <FormControlLabel
                                    control={<Checkbox checked={riskInfoChecked} onChange={(e) => setRiskInfoChecked(e.target.checked)} />}
                                    label={
                                        <Typography>
                                            Ich nehme die <a href='#' onClick={() => DataService.downloadAttachment(
                                                project.riskInformationDocumentFileRef,
                                                project.riskInformationDocumentFileRef.split('?name=')[1].replace('.pdf', ''),
                                            )}>Risikoinformationen</a> zur Kenntnis.
                                        </Typography>
                                    }
                                />
                            )}

                            {project?.loanConditionsDocumentFileRef && (
                                <FormControlLabel
                                    control={<Checkbox checked={termsChecked} onChange={(e) => setTermsChecked(e.target.checked)} />}
                                    label={
                                        <Typography>
                                            Ich bin mit der Geltung der <a href='#' onClick={() => DataService.downloadAttachment(
                                                project.loanConditionsDocumentFileRef,
                                                project.loanConditionsDocumentFileRef.split('?name=')[1].replace('.pdf', ''),
                                            )}>Zeichnungsbedingungen</a> einverstanden.
                                        </Typography>
                                    }
                                    sx={{ marginBottom: 2 }}
                                />
                            )}

                            <Alert severity="info" sx={{ mb: 2 }}>
                                Wenn Sie mit dem Vertrag einverstanden sind, dann klicken Sie bitte auf den Button "Darlehensvertrag rechtskräftig abschließen". Andernfalls kontaktieren Sie uns bitte per Mail!
                            </Alert>

                            <Stack spacing={2} direction='column' width='100%'>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Button
                                        variant='contained'
                                        onClick={() => setIsPreview(true)}
                                        sx={{ backgroundColor: 'grey', color: 'white' }}
                                    >
                                        Zurück
                                    </Button>
                                    <Button
                                        disabled={!riskInfoChecked || !termsChecked || !contractDownloaded || validationErrors.length > 0}
                                        variant='contained'
                                        color='primary'
                                        onClick={async () => {
                                            await DataService.signContract(props.task.id);
                                            navigate('/projectloans');
                                        }}
                                    >
                                        Darlehensvertrag zahlungspflichtig abschließen
                                    </Button>
                                </div>
                            </Stack></>)}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
