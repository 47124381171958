import { Alert, Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import AuthService from '../../services/AuthService';
import DataService from '../../services/DataService';
import EmptyTypesService from '../../services/EmptyTypesService';
import InputValue from '../../classes/InputValue';
import { Loading } from '../../components/Loading';
import { LoadingButton } from '@mui/lab';
import { MemberDto } from '../../types';
import MemberService from '../../services/MemberService';
import { useNavigate } from 'react-router-dom';
import { validationService } from '../../validation/ValidationService';

interface EditPersonalDataProps {
    isMobile: boolean;
    config: Config;
}

export const EditPersonalData: React.FC<EditPersonalDataProps> = (props) => {
    const navigate = useNavigate();
    const [finishedLoading, setFinishedLoading] = useState<{ editMember: boolean }>({ editMember: false });
    //boolean if site is currently doing validations => shows "Speichern"-Button if it should show a loading circle
    const [saveBtnIsLoading, setSaveBtnIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [memberData, setMemberData] = useState<MemberDto>(EmptyTypesService.emptyMember());

    const [values] = useState<any>({
        salutation: new InputValue('', false),
        title: new InputValue('', false),
        firstname: new InputValue('', false),
        lastname: new InputValue('', false),
        addressStreet: new InputValue('', false),
        addressExtra: new InputValue('', false),
        addressPostalcode: new InputValue('', false),
        addressCity: new InputValue('', false),
        phone: new InputValue('', false),
        birthdate: new InputValue('', false),
        birthcity: new InputValue('', false),
        taxnumber: new InputValue('', false),
    });

    useEffect(() => {
        if (!AuthService.isLoggedIn()) {
            AuthService.doLogin();
        } else {
            DataService.performAsyncRequest(`/member/info${MemberService.getUrlAddition()}`, finishedLoading, () => {
            }, setMemberData, 'member', navigate)
                //data has to be of Contact type as it's member request. Can't work with memberData object, as at this point it is still undefined, because site was not reloaded with new attributes, so we have to work with raw data directly (but it is the same as memberData)

                .then((response) => {
                    //@ts-ignore
                    const rawObject: MemberDto = response.data;
                    //memberData into values array
                    values.salutation.setValue(rawObject['salutation']);
                    values.title.setValue(rawObject['title'], false);
                    values.firstname.setValue(rawObject['firstname'], false);
                    values.lastname.setValue(rawObject['lastname'], false);
                    values.addressStreet.setValue(rawObject['address_street'], false);
                    values.addressExtra.setValue(rawObject['address_extra'], false);
                    values.addressPostalcode.setValue(rawObject['address_postalcode'], false);
                    values.addressCity.setValue(rawObject['address_city'], false);
                    values.phone.setValue(rawObject['phone'], false);
                    values.birthdate.setValue(rawObject['birthdate']?.substring(0, 10), false);
                    values.birthcity.setValue(rawObject['birthplace'], false);
                    values.taxnumber.setValue(rawObject['taxNumber'], false);
                    setFinishedLoading({ editMember: true });
                });
        }

    }, []);

    //a useState var needs to change in order to reload the screen, so you can do that with reload(!reloadVar);
    const [reloadVar, reload] = useState<boolean>(false);

    //Save request
    const saveContact = async (setSaveBtnIsLoading: Function) => {
        DataService.performAsyncPutRequest(`/rest/entities/beg_Contacts/${memberData.id}`, navigate,
            {
                id: memberData.id,
                salutation: values.salutation.getValue(),
                title: values.title.getValue(),
                firstname: values.firstname.getValue(),
                lastname: values.lastname.getValue(),
                address_street: values.addressStreet.getValue(),
                address_extra: values.addressExtra.getValue(),
                address_postalcode: values.addressPostalcode.getValue(),
                address_city: values.addressCity.getValue(),
                phone: values.phone.getValue(),
                birthdate: values.birthdate.getValue(),
                birthplace: values.birthcity.getValue(),
                taxNumber: values.taxnumber.getValue(),
            },
        ).then(() => {
            setSaveBtnIsLoading(false);
            navigate('/personaldata');
        });
    };

    //COPY OF VALIDATE FUNCTION FROM Antrag.tsx BUT setIsError instead of setProgressColor
    //checks validation of value and returns if value is ok (adapts to state with isNextStep)
    const validate = (name: string, checkFor: string, isNextStep: boolean, isRequired: boolean) => {
        const value = values[name].getValue();
        if (isNextStep && isRequired) {
            if (typeof parseInt(value) === 'number' && checkFor === 'number') {
                if (value < 1 || value > props.config.maxAllowedHeldShares) {
                    values[name].setError(true);
                    return false;
                }
            } else {
                if (!value || value.length < 1) {
                    values[name].setError(true);
                    return false;
                }
            }
            //only iban field (sepa) is required and should not only be checked onBlur but also onNextStep
            if (checkFor === 'iban') {
                if (values[name].getError()) return false;
            }
        } else {
            if (typeof parseInt(value) == 'number' && checkFor === 'number') {
                if (value.length < 1) {
                    values[name].setError(false);
                    return true;
                }
                if (value < 1 || value > props.config.maxAllowedHeldShares) {
                    values[name].setError(true);
                    return false;
                }
            } else {
                if (!value || value.length < 1) {
                    values[name].setError(false);
                    return true;
                }
            }
        }
        if (!validationService[checkFor](value)) {
            values[name].setError(true);
            return false;
        }
        values[name].setError(false);
        return true;
    };

    const style = {
        marginTop: '10px',
    };
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, checkFor: string) => {
        const target = e.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        if (name === 'taxnumber') {
            value = (value as string).replaceAll(' ', '');
        }
        values[name].setValue(value);
        //in this step required can be false (even if its true) because required is checked on submit, not on change
        validate(name, checkFor, false, false);
        reload(!reloadVar);
    };

    if (!finishedLoading.editMember) {
        return <Loading />;
    }
    if (memberData.type! == 'beg_Accounts') {
        return <Box
            className='content'
            display='flex'
            justifyContent='center'
            alignItems='flex-start'
            textAlign='center'
            padding={2}
        ><Box
            className='tableDiv'
            width={props.isMobile ? '100%' : '1024px'}
            display='inline-block'
        ><Alert severity="info" sx={{ maxWidth: '400px', margin: '0 auto' }}>Die Bearbeitung von Daten für Organisationen wird aktuell leider noch nicht unterstützt. Bitte wenden Sie sich für Änderungen per Mail an uns!</Alert>
            </Box>
        </Box>;
    }
    return (
        <Box
            className='content'
            display='flex'
            justifyContent='center'
            alignItems='flex-start'
            padding={2}
        ><Box
            className='tableDiv'
            width={props.isMobile ? '100%' : '1024px'}
            display='inline-block'
        >
                <Typography variant='h6'>Persönliche Daten bearbeiten</Typography>
                <FormControl style={style} fullWidth>
                    <InputLabel size='small'>Anrede *</InputLabel>
                    <Select
                        value={values.salutation.getValue()}
                        label='Anrede'
                        name='salutation'
                        variant='outlined'
                        size='small'
                        //Not compatible with handlechange() as it's a different event
                        onChange={(e: SelectChangeEvent<HTMLInputElement>) => {
                            values[e.target.name].setValue(e.target.value);
                            reload(!reloadVar);
                        }}
                        error={values.salutation.getError()}
                        required
                    >
                        <MenuItem value={'Herr'}>Herr</MenuItem>
                        <MenuItem value={'Frau'}>Frau</MenuItem>
                        <MenuItem value={'Keine Anrede'}>Keine Anrede</MenuItem>
                    </Select>
                </FormControl>
                <TextField style={style} fullWidth value={values.title.getValue()} error={values.title.getError()} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, 'alphabet')} name='title' label='Titel' variant='outlined'
                    size='small' />
                <TextField style={style} fullWidth value={values.firstname.getValue()} error={values.firstname.getError()} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, 'alphabet')} name='firstname' label='Vorname'
                    variant='outlined' size='small' required />
                <TextField style={style} fullWidth value={values.lastname.getValue()} error={values.lastname.getError()} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, 'alphabet')} name='lastname' label='Nachname'
                    variant='outlined' size='small' required />
                <TextField style={style} fullWidth value={values.addressStreet.getValue()} error={values.addressStreet.getError()} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, 'street')} name='addressStreet'
                    label='Straße, Hausnummer' variant='outlined' size='small' required />
                <TextField style={style} fullWidth value={values.addressExtra.getValue()} error={values.addressExtra.getError()} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, 'alphabet')} name='addressExtra'
                    label='Addresszusatz (optional)' variant='outlined' size='small' />
                <TextField style={style} fullWidth value={values.addressPostalcode.getValue()} error={values.addressPostalcode.getError()} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, 'postalcode')}
                    name='addressPostalcode' label='Postleitzahl' variant='outlined' size='small' required />
                <TextField style={style} fullWidth value={values.addressCity.getValue()} error={values.addressCity.getError()} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, 'alphabet')} name='addressCity' label='Ort'
                    variant='outlined' size='small' required />
                <TextField style={style} fullWidth value={values.phone.getValue()} error={values.phone.getError()} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, 'phone')} name='phone' type='tel'
                    label='Telefon (optional)' variant='outlined' size='small' />

                <Typography variant='body1' style={{ ...style, width: '125px', display: 'inline-block', padding: '8px 0px' }}>Geburtsdatum*</Typography>
                <TextField style={{ ...style, width: 'calc(100% - 125px)', display: 'inline-block' }} fullWidth value={values.birthdate.getValue()} error={values.birthdate.getError()}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, 'birthdate')} name='birthdate' type='date' variant='outlined' size='small' required />
                <TextField style={style} fullWidth value={values.birthcity.getValue()} error={values.birthcity.getError()} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, 'alphabet')} name='birthcity'
                    label='Geburtsort (optional)' variant='outlined' size='small' />
                <TextField style={style} fullWidth value={values.taxnumber.getValue()} error={values.taxnumber.getError()} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, 'taxnumber')} name='taxnumber'
                    label='Steuer-ID (optional)' variant='outlined' size='small' helperText='spätestens benötigt für Zinsauszahlung' />
                <div style={{ textAlign: 'right' }}>
                    {isError && <Typography variant='caption' color={'#d32f2f'}>Bitte überprüfen Sie ihre Eingabe und speichern erneut</Typography>}
                </div>
                <div style={{ textAlign: 'right' }}>
                    <LoadingButton loading={saveBtnIsLoading} variant='contained' disableElevation sx={{ marginTop: '10px' }} onClick={async () => {
                        setSaveBtnIsLoading(true);
                        let isError = false;
                        //name,checkFor,isNextStep,isRequired
                        if (!validate('salutation', 'alphabet', true, true)) isError = true;
                        if (!validate('title', 'alphabet', true, false)) isError = true;
                        if (!validate('firstname', 'alphabet', true, true)) isError = true;
                        if (!validate('lastname', 'alphabet', true, true)) isError = true;
                        if (!validate('addressStreet', 'street', true, true)) isError = true;
                        if (!validate('addressExtra', 'alphabet', true, false)) isError = true;
                        if (!validate('addressPostalcode', 'postalcode', true, true)) isError = true;
                        if (!validate('addressCity', 'alphabet', true, true)) isError = true;
                        if (!validate('phone', 'phone', true, false)) isError = true;
                        if (!validate('birthdate', 'birthdate', true, true)) isError = true;
                        if (!validate('birthcity', 'alphabet', true, false)) isError = true;
                        if (!validate('taxnumber', 'taxnumber', true, false)) isError = true;
                        reload(!reloadVar);
                        if (!isError) {
                            setIsError(false);
                            saveContact(setSaveBtnIsLoading);
                        } else {
                            setIsError(true);
                            setSaveBtnIsLoading(false);
                        }
                    }}>Speichern</LoadingButton>

                </div>

            </Box>
        </Box>
    );
};
